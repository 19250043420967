<template>
  <div>
    <!-- Sélection de l'adresse avec option intégrée pour nouveau compte -->
    <div class="address-selection">
      <input type="hidden" name="invoiceRecipientAccountId" v-model="edit.InvoiceAccount">
      <el-select 
        name="invoiceRecipientAccount" 
        v-model="edit.invoiceRecipientAccount" 
        class="select-dropdown" 
        placeholder="Choisir une adresse" 
        size="large" 
        :value="edit.invoiceRecipientAccount"
        @change="onAccountSelect">
        <el-option
            v-for="opt in existingAccounts"
            :key="opt.value"
            :label="opt.text"
            :value="opt.value"
        />
        <el-option
          key="new"
          label="+ Nouveau compte facturation"
          value="new"
        />
      </el-select>
    </div>

    <!-- Formulaire de nouveau compte de facturation (s'affiche quand demandé) -->
    <div v-if="showNewAccountForm" class="form-section">
      <div class="form-card">
        <div class="form-header">
          <button type="button" class="btn-back" @click="cancelNewAccount">
            <i class="fas fa-arrow-left"></i> Retour
          </button>
        </div>
        <AddBillingAccount v-model="newAccount" @edit="onEditNewInvoiceAccount" :hideManualAddressLink="true"/>
      </div>
    </div>

    <!-- Message d'erreur -->
    <div v-if="error" class="alert alert-danger">
      {{ error }}
    </div>
    
    <!-- Boutons d'action -->
    <div class="form-actions">
      <button
        type="button"
        class="btn-cancel"
        @click="cancelForm">
        Annuler
      </button>
      <button 
        type="button"
        class="btn-primary"
        :disabled="saveLoading"
        @click.prevent="onSaveBillingAccount">
        <span v-if="saveLoading" class="loading-spinner"></span>
        {{ customTranslations('change-facturation.button') }}
      </button>
    </div>
  </div>
</template>

<script>
import loadContent from "../../../mixins/loadContent.js";
import BillingAccount from "../services/BillingAccount.js";
import AddBillingAccount from "./AddBillingAccount.vue";
import {transformDataToFormContent} from "~/services/parameters.js";
import Rental from "~/modules/ask/services/Rental.js";

export default {
  name: "ChangeRecipientAccount",
  components: {AddBillingAccount},
  fetchUrl: 'recipient-accounts',
  mixins: [loadContent],
  data () {
    return {
      saveLoading: false,
      error: null,
      edit: {
        invoiceRecipientAccount: this.$symfony && this.$symfony.defaultInvoiceAccount ? this.$symfony.defaultInvoiceAccount.value : null
      },
      newAccount: false,
      showNewAccountForm: false
    }
  },
  async mounted() {
    if (!this.$route) {
      try {
        await this.beforeDataFetch()
      } catch(e) {
        console.error(e)
      }
    }
  },
  props: {
    rentalId: {
      type: Number,
      default: null
    },
    showSaveButton: {
      type: Boolean,
      default: false
    }
  },
  emits: ['addressChanged', 'cancel'],
  methods: {
    cancelForm() {
      console.log('Cancel button clicked');
      this.$emit('cancel');
    },
    onAccountSelect(value) {
      if (value === 'new') {
        this.showNewAccountForm = true;
      } else {
        this.showNewAccountForm = false;
      }
    },
    cancelNewAccount() {
      this.showNewAccountForm = false;
      this.edit.invoiceRecipientAccount = this.$symfony && this.$symfony.defaultInvoiceAccount 
        ? this.$symfony.defaultInvoiceAccount.value 
        : null;
    },
    customTranslations(key) {
      // Remplacer spécifiquement le texte pour le bouton
      if (key === 'change-facturation.button') {
        return 'Modifier facturation';
      }
      // Pour les autres clés, utiliser le système de traduction standard
      return this.$t(key);
    },
    async onSaveBillingAccount ($event) {
      if ($event) {
        $event.preventDefault();
      }
      
      // Reset error state
      this.error = null;
      
      // Default empty data object
      let data = {};
      
      // Add invoice account ID if available
      if (this.edit && this.edit.invoiceRecipientAccount) {
        data.invoiceRecipientAccountId = this.edit.invoiceRecipientAccount;
      }
      
      // Handle new account data if available
      if (this.newAccount) {
        if (this.newAccount.isCompany) {
          // For company accounts
          if (this.newAccount.company) {
            data = {
              ...data,
              ...(this.newAccount.company || {}),
              isCompany: 'on'
            };
            
            // Add address data if available
            if (this.newAccount.company.address) {
              data = {
                ...data,
                ...(this.newAccount.company.address || {})
              };
            }
            
            // Remove any nested address object
            if (data.address) {
              delete data.address;
            }
          }
        } else {
          // For individual accounts
          data = {
            ...data,
            ...(this.newAccount || {}),
            isCompany: 'off'
          };
          
          // Add address data if available
          if (this.newAccount.address) {
            data = {
              ...data,
              ...(this.newAccount.address || {})
            };
          }
          
          // Remove any nested address object
          if (data.address) {
            delete data.address;
          }
        }
      }

      try {
        await this.sendData(data, false);
      } catch (error) {
        console.error('Error saving billing account:', error);
        this.error = error.message || "Une erreur s'est produite lors de la sauvegarde. Veuillez réessayer.";
      }
    },
    onEditNewInvoiceAccount (val) {
      this.newAccount = val
      console.log('edit new invoice account')
      console.log(val)
    },
    async sendData (data, form = 'fos_user_registration_form') {
      // Set loading state immediately
      this.saveLoading = true;
      this.error = null;
      
      try {
        // Add CSRF token if available
        if (this._token) {
          data['_token'] = this._token;
        }
        
        // Format the data according to the form requirements
        let dataFormContent = {};
        if (form) {
          dataFormContent[form] = {...data};
        } else {
          dataFormContent = {...data};
        }
        
        // Transform data to form content
        let dataToSend = transformDataToFormContent(dataFormContent, []);
        
        // Build the API URL
        let url = '/api/user/save-tenant-billing-account/';
        if (this.rentalId) {
          url += this.rentalId;
        }
        
        console.log('Sending data to:', url);
        console.log('Data being sent:', JSON.stringify(dataToSend));
        
        // Make the API request
        const response = await Rental.api().post(url, dataToSend, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json'
          },
          timeout: 30000 // 30 seconds timeout
        });
        
        // Process successful response
        if (response && response.response && response.response.data) {
          console.log('Response received:', response.response.data);
          
          // Handle form errors
          if (response.response.data.form_errors) {
            this.formErrors = response.response.data.form_errors;
            
            // Display the first error message if available
            if (this.formErrors.globalError) {
              this.error = this.formErrors.globalError;
            } else {
              // Find the first error message
              for (const key in this.formErrors) {
                if (this.formErrors[key]) {
                  this.error = this.formErrors[key];
                  break;
                }
              }
            }
          } else {
            // Clear form errors if successful
            this.formErrors = null;
          }
          
          // Update CSRF token
          if (response.response.data.token_csrf) {
            this._token = response.response.data.token_csrf;
          }
          
          // Handle error messages
          if (response.response.data.error) {
            this.error = response.response.data.error;
          }
          
          // Handle CSRF token validation errors
          if (response.response.data.csrf_token_valid === false) {
            this.error = 'Le token csrf est expiré. Veuillez recharger la page et réessayer.';
          }
          
          // Update the default invoice account if successful
          if (response.response.data.invoiceRecipientAccountSelected) {
            if (this.$symfony) {
              this.$symfony.defaultInvoiceAccount = response.response.data.invoiceRecipientAccountSelected;
            }
            
            // Reset the form if successful
            if (!this.error) {
              this.newAccount = false;
              this.showNewAccountForm = false;
              this.edit.invoiceRecipientAccount = response.response.data.invoiceRecipientAccountSelected.value;
            }
          }
          
          // Update the billing account in store
          if (response.response.data.billingAccount) {
            await BillingAccount.insertOrUpdate({
              data: response.response.data.billingAccount
            });
          }
          
          // Update the rental in store
          if (response.response.data.rental) {
            await Rental.insertOrUpdate({
              data: response.response.data.rental
            });
          }
          
          // Emit the address changed event if successful
          if (!this.error) {
            this.$emit('addressChanged');
          }
        }
        
        return response;
      } catch (error) {
        console.error('Error during data submission:', error);
        this.error = error.message || "Une erreur s'est produite lors de la sauvegarde. Veuillez réessayer.";
        throw error;
      } finally {
        this.saveLoading = false;
      }
    }
  },
  computed: {
    invoiceAccountId () {
      return this.edit.invoiceRecipientAccount
    },
    Model () {
      return BillingAccount
    },
    existingAccounts() {
      // Filtre pour n'avoir que les comptes existants (pas l'option "nouveau")
      let accounts = [];
      if (Array.isArray(this.UserInvoiceAccounts)) {
        accounts = this.UserInvoiceAccounts;
      }
      return accounts;
    },
    InvoiceAccountsSelect () {
      // Handle the construction of a value for the new account more safely
      let newAccountValue = []
      if (this.newAccount) {
        const firstname = this.newAccount.firstname || ''
        const lastname = this.newAccount.lastname || ''
        let displayText = ''
        
        if (this.newAccount.isCompany) {
          const companyName = this.newAccount.company && this.newAccount.company.name ? this.newAccount.company.name : ''
          displayText = companyName
        } else {
          displayText = `${firstname} ${lastname}`
        }
        
        let address = ''
        if ((this.newAccount.isCompany && this.newAccount.company && this.newAccount.company.address) || 
            (!this.newAccount.isCompany && this.newAccount.address)) {
          const addressObj = this.newAccount.isCompany ? this.newAccount.company.address : this.newAccount.address
          if (addressObj) {
            const numero = addressObj.numero || ''
            const rue = addressObj.rue || ''
            const codePostal = addressObj.codePostal || ''
            const ville = addressObj.ville || ''
            const pays = addressObj.pays || ''
            address = `${numero} ${rue}, ${codePostal} ${ville}, ${pays}`
          }
        } else {
          address = 'Ajouter une adresse de facturation'
        }
        
        if (displayText.trim() !== '') {
          newAccountValue = [{
            text: displayText + ', ' + address,
            value: 'new'
          }]
        } else {
          newAccountValue = [{
            text: 'Nouveau compte de facturation',
            value: 'new'
          }]
        }
      } else {
        newAccountValue = [{
          text: 'Nouveau compte de facturation',
          value: 'new'
        }]
      }
      
      // Safely combine with existing accounts
      let existingAccounts = []
      if (Array.isArray(this.UserInvoiceAccounts)) {
        existingAccounts = this.UserInvoiceAccounts
      }
      
      return [...existingAccounts, ...newAccountValue]
    },
    UserDefaultAccount () {
      return this.$symfony && this.$symfony.defaultInvoiceAccount ? this.$symfony.defaultInvoiceAccount.text : null
    },
    UserInvoiceAccounts () {
      if (!Array.isArray(this.modelList) && !this.UserDefaultAccount) {
        return []
      } else if (!this.modelList.length && this.UserDefaultAccount && this.$symfony && this.$symfony.defaultInvoiceAccount) {
        return [{
          text: this.$symfony.defaultInvoiceAccount.text,
          value: this.$symfony.defaultInvoiceAccount.value
        }]
      }
      return this.modelList.map((invoiceAccount) => {
        return {
          text: invoiceAccount.text,
          value: invoiceAccount.id
        }
      })
    }
  }
}
</script>

<style scoped>
/* Styles pour la sélection d'adresse */
.address-selection {
  margin-bottom: 20px;
}

.select-dropdown {
  width: 100%;
}

/* Style pour le formulaire */
.form-section {
  margin-top: 20px;
}

.form-card {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #e6e6e6;
}

.form-header {
  display: flex;
  margin-bottom: 15px;
}

.btn-back {
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 0;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.btn-back:hover {
  color: #333;
}

.btn-back i {
  margin-right: 5px;
}

/* Styles pour les boutons d'action */
.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-cancel {
  background-color: #fff;
  color: #6c757d;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-cancel:hover {
  background-color: #f8f9fa;
}

.btn-primary {
  background-color: #FF385C;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  padding: 10px 25px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-primary:hover {
  background-color: #e9344f;
}

.btn-primary:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Message d'erreur */
.alert {
  padding: 12px 16px;
  border-radius: 8px;
  margin: 16px 0;
  font-size: 14px;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Loading spinner */
.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  margin-right: 8px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>