<template>
  <div class="billing-form p-2">
    <!-- Checkbox pour compte professionnel -->
    <div class="form-check mb-3">
      <div class="checkbox-container">
        <input
          type="checkbox"
          name="isCompany"
          id="use-personnal-adress"
          v-model="edit.isCompany"
          class="form-check-input"
        />
        <label for="use-personnal-adress" class="form-check-label">
          Compte professionnel
        </label>
      </div>
    </div>
    
    <!-- Formulaire Compte Professionnel -->
    <div v-if="edit.isCompany">
      <AddCompany @edit="onEdit" />
    </div>
    
    <!-- Formulaire Compte Personnel -->
    <div v-else class="personal-form">
      <!-- Prénom et Nom côte à côte -->
      <div class="name-fields-row">
        <!-- Prénom -->
        <div class="form-field mb-3 name-field">
          <label for="firstname" class="field-label">Prénom</label>
          <el-input
            name="firstname"
            type="text"
            id="firstname"
            size="default"
            class="field-input old-ui"
            v-model="edit.firstname"
          />
        </div>
        
        <!-- Nom -->
        <div class="form-field mb-3 name-field">
          <label for="lastname" class="field-label">Nom</label>
          <el-input
            name="lastname"
            type="text"
            id="lastname"
            size="default"
            class="field-input old-ui"
            v-model="edit.lastname"
          />
        </div>
      </div>

      <!-- Adresse de facturation -->
      <div class="form-field mb-3">
        <label class="field-label">Adresse de facturation</label>
        <input-city
          v-model="edit.address"
          placeholder="24 rue mouftard 75008 Paris"
          :manual-mode="true"
          :label="''"
          class="address-input"
          :hide-manual-link="true"
        />
        <input v-for="hf in hiddenFields" :key="hf.name" type="hidden" :name="hf.name" v-model="hf.value">
      </div>
    </div>
  </div>
</template>

<script>
// import formHandler from "../../../mixins/formHandler.js";
import InputCity from "../../../components/parameters/inputCity.vue";
import AddCompany from "./AddCompany.vue";

export default {
  name: "AddBillingAccount",
  components: { AddCompany, InputCity },
  // mixins: [formHandler],
  props: {
    hideManualAddressLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contentLoaded: false,
      edit: {
        isCompany: false,
        company: {
          siret: ""
        },
        address: {},
        firstname: "",
        lastname: ""
      },
      content: {
        company: {
          siret: ""
        },
        address: {
          firstname: "",
          lastname: ""
        }
      }
    };
  },
  watch: {
    edit: {
      handler: function(val, oldVal) {
        this.$emit("edit", val);
      },
      deep: true
    }
  },
  computed: {
    hiddenFields () {
      return Object.keys(this.edit.address).map(key => {
        return {
          name: key,
          value: this.edit.address[key]
        }
      })
    }
  },
  methods: {
    onEdit(val) {
      if (val) {
        if (val && val.company) {
          this.edit.company = val.company;
        }
      }
    },
    manualAddress() {
      // Pour simuler l'action d'ajout manuel d'adresse
      console.log("Ajouter l'adresse manuellement");
    }
  }
};
</script>

<style scoped>
.billing-form {
  background-color: #f8f9fa;
  border-radius: 8px;
}

.form-check {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.form-check-input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  accent-color: #FF385C;
  cursor: pointer;
}

.form-check-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
}

.name-fields-row {
  display: flex;
  gap: 15px;
}

.name-field {
  flex: 1;
}

.form-field {
  margin-bottom: 12px;
}

.field-label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.field-input {
  width: 100%;
  border-radius: 6px;
}

.personal-form {
  display: flex;
  flex-direction: column;
}

.address-input {
  font-size: 14px;
}

.add-address-link {
  font-size: 13px;
}

.add-address-link a {
  color: #FF385C;
  text-decoration: none;
}

.add-address-link a:hover {
  text-decoration: underline;
}

.p-2 {
  padding: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mt-2 {
  margin-top: 0.5rem;
}
</style>
