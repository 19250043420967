<template>
  <div class="company-form">
    <!-- Champ SIRET mis en évidence -->
    <div class="form-field mb-3">
      <label class="field-label" for="siret">
        <strong>Numéro Siret</strong>
        <span class="hint-text">(recherche automatique)</span>
      </label>
      <el-input
        name="siret"
        id="siret"
        v-model="edit.company.siret"
        @input="onSiretChange"
        size="default"
        class="field-input old-ui"
        placeholder="Saisissez le numéro SIRET à 14 chiffres"
      >
        <template #suffix>
          <span v-show="numberOfSiretRequest > 0" class="loading-text"><i class="fas fa-spinner fa-spin"></i> Vérification...</span>
        </template>
      </el-input>
    </div>

    <!-- Message d'info après recherche SIRET -->
    <div v-if="siret" class="info-message mb-3">
      <i class="fas fa-check-circle"></i> Les informations ont été récupérées automatiquement grâce au SIRET
    </div>

    <!-- Nom société -->
    <div class="form-field mb-3" v-if="siret || vat || editCompanyName">
      <label class="field-label" for="company-name">Nom société</label>
      <el-input
        v-if="siret"
        name="companyName"
        id="company-name"
        v-model="siret.nom_entreprise"
        size="default"
        class="field-input old-ui auto-filled"
        disabled
      />
      <el-input
        v-else-if="vat && !vat.valid"
        name="companyName"
        id="company-name"
        v-model="edit.company.name"
        class="field-input old-ui"
        size="default"
      />
      <el-input
        v-else-if="vat"
        name="companyName"
        id="company-name"
        v-model="vat.name"
        class="field-input old-ui auto-filled"
        size="default"
        disabled
      />
      <el-input
        v-else-if="editCompanyName"
        name="companyName"
        id="company-name"
        v-model="edit.company.name"
        class="field-input old-ui"
        size="default"
      />
    </div>

    <!-- TVA Checkbox -->
    <div class="form-check mb-3">
      <div class="checkbox-container">
        <input
          type="checkbox"
          name="hasVat"
          id="hasVat"
          v-model="edit.hasVat"
          class="form-check-input"
        />
        <label for="hasVat" class="form-check-label">
          Je suis assujetti à la TVA
          <span class="optional-badge">Optionnel</span>
        </label>
      </div>
    </div>

    <!-- Numéro TVA -->
    <div v-show="edit.hasVat" class="form-field mb-3">
      <label class="field-label" for="tax-id">Numéro TVA intracommunautaire</label>
      <el-input
        v-if="!siret"
        name="taxId"
        id="tax-id"
        v-model="edit.company.taxId"
        @input="onVATidChange"
        size="default"
        class="field-input old-ui"
      ></el-input>
      <el-input
        v-else
        name="taxId"
        id="tax-id"
        v-model="siret.numero_tva_intracommunautaire"
        size="default"
        class="field-input old-ui auto-filled"
        disabled
      />
    </div>

    <!-- Siège -->
    <div class="form-field mb-3">
      <label class="field-label" for="siege">Siège</label>
      <input-city
        v-if="!siret && !vat"
        name="siege"
        id="siege"
        v-model="edit.company.address"
        size="default"
        class="field-input old-ui"
        placeholder="24 rue mouftard 75008 Paris"
        :label="''"
        :hide-manual-link="true"
      ></input-city>
      <input-city
        name="siege"
        v-else-if="vat && !vat.valid"
        v-model="edit.company.address"
        placeholder="24 rue mouftard 75008 Paris"
        :label="''"
        :hide-manual-link="true"
      />
      <el-input
        v-else
        name="siege"
        id="siege"
        v-model="siretAddress"
        size="default"
        class="field-input old-ui auto-filled"
        disabled
      />
    </div>

    <!-- Hidden inputs -->
    <input v-if="siretAddress" name="siege" type="hidden" :value="siretAddress" />
    <input v-if="vat && vat.countryCode" type="hidden" name="country_code" v-model="vat.countryCode">
    <input v-for="hf in hiddenFields" :key="hf.name" type="hidden" :name="hf.name" v-model="hf.value">
  </div>
</template>

<script>
import { throttle } from "../../../utils/TimeExecution.js";
import { loadPageContent } from "../../../services/parameters.js";
import InputCity from "~/components/parameters/inputCity.vue";

export default {
  name: "AddCompany",
  components: {InputCity},
  // mixins: [loadContent],
  fetchUrl: "analyze-siret",
  props: {
  },
  data() {
    return {
      numberOfSiretRequest: 0,
      siret: false,
      vat: false,
      edit: {
        hasVat: true,
        company: {
          name: "",
          siret: "",
          taxId: "",
          addressName: "",
          address: {}
        },
        address: {}
      },
      content: {
        company: {
          siret: ""
        },
        addressName: "",
        address: {}
      }
    };
  },
  watch: {
    edit: {
      handler: function(val, oldVal) {
        this.$emit("edit", val)
      },
      deep: true
    }
  },
  computed: {
    hiddenFields () {
      return Object.keys(this.edit.company.address).map(key => {
        let value = null
        if (typeof this.edit.address[key] !== 'undefined') {
          value = this.edit.address[key]
        } else {
          value = this.edit.company.address[key]
        }
        return {
          name: key,
          value: value
        }
      })
    },
    editCompanyName() {
      return this.edit.company.siret && this.edit.company.siret.length > 3 && this.edit.company.taxId && this.edit.company.taxId.length > 2 ;
    },
    siretAddress() {
      if (this.siret) {
        let adresse = this.siret.siege && this.siret.siege.adresse_ligne_1 ? this.siret.siege.adresse_ligne_1 : ''
        let codePostal = this.siret.siege && this.siret.siege.code_postal ? this.siret.siege.code_postal : ''
        let ville = this.siret.siege && this.siret.siege.ville ? this.siret.siege.ville : ''
        let pays = this.siret.siege && this.siret.siege.pays ? this.siret.siege.pays : ''
        return (adresse +
            ", " +
            codePostal +
            " " +
            ville + ', ' + pays
        );
      } else if (this.vat) {
        return (this.vat.address)
      }
    }
  },
  methods: {
    verifySiretThrottle: throttle(
      function() {
        this.verifySiret();
      },
      1000,
      true,
      true
    ),
    verifyVatIdThrottle: throttle(
      function() {
        this.verifyVatId();
      },
      1000,
      true,
      true
    ),
    onSiretChange() {
      console.log("onSiretChange");
      this.verifySiretThrottle();
    },
    onVATidChange() {
      console.log("onSiretChange");
      this.verifyVatIdThrottle();
    },
    verifySiret() {
      console.log("fetchData");
      this.numberOfSiretRequest++
      loadPageContent("/api/verify-siret/" + this.edit.company.siret)
        .then(response => {
          this.siret = response.data.siret;
          let vat = response.data.vat;
          if (this.siret) {
            this.edit.company.name = this.siret.nom_entreprise
            this.edit.company.taxId = this.siret.numero_tva_intracommunautaire
            let adresse = this.siret.siege && this.siret.siege.adresse_ligne_1 ? this.siret.siege.adresse_ligne_1 : ''
            let adresse2 = this.siret.siege && this.siret.siege.adresse_ligne_2 ? this.siret.siege.adresse_ligne_2 : ''
            let rue = this.siret.siege && this.siret.siege.type_voie && this.siret.siege.libelle_voie ? this.siret.siege.type_voie + ' ' + this.siret.siege.libelle_voie : ''
            let numero = this.siret.siege && this.siret.numero_voie ? this.siret.numero_voie : ''
            let codePostal = this.siret.siege && this.siret.siege.code_postal ? this.siret.siege.code_postal : ''
            let ville = this.siret.siege && this.siret.siege.ville ? this.siret.siege.ville : ''
            let pays = this.siret.siege && this.siret.siege.pays ? this.siret.siege.pays : ''
            let latitude = this.siret.siege && this.siret.siege.latitude ? this.siret.siege.latitude : ''
            let longitude = this.siret.siege && this.siret.siege.longitude ? this.siret.siege.longitude : ''
            let location = this.siret.siege && this.siret.siege.longitude && this.siret.siege.latitude ? this.siret.siege.latitude + ',' + this.siret.siege.longitude : ''
            this.edit.company.addressName = (adresse +
              ", " +
                codePostal +
              " " +
              ville + ', ' + pays
            )
            this.edit.company.address = {
              adresse,
              adresse2,
              codePostal,
              numero,
              rue,
              pays,
              ville,
              location,
              "lat": latitude,
              "lng": longitude
            }
          } else if (vat && vat.valid) {
            this.edit.company.name = vat.name + ''
            this.edit.company.addressName = vat.address + ''
          }
        })
        .catch(e => {
          console.error(e);
        })
        .finally(() => {
          this.numberOfSiretRequest--
        })
      ;
    },
    verifyVatId() {
      loadPageContent("/api/verify-vat-id/" + this.edit.company.taxId)
        .then(response => {
          this.vat = response.data.vat;
          if (this.vat && this.vat.valid) {
            this.edit.company.name = this.vat.name + ''
            this.edit.company.addressName = this.vat.address + ''
          }
        })
        .catch(e => {
          console.error(e);
        });
    }
  }
};
</script>

<style scoped>
.company-form {
  display: flex;
  flex-direction: column;
}

.form-field {
  margin-bottom: 12px;
}

.field-label {
  display: block;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.field-input {
  width: 100%;
  border-radius: 6px;
}

.hint-text {
  font-weight: normal;
  font-size: 12px;
  color: #666;
  margin-left: 5px;
}

.loading-text {
  font-size: 14px;
  color: #6c757d;
}

.form-check {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.form-check-input {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  accent-color: #FF385C;
  cursor: pointer;
}

.form-check-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  cursor: pointer;
}

.optional-badge {
  display: inline-block;
  font-size: 12px;
  color: #6c757d;
  font-weight: normal;
  margin-left: 6px;
}

.auto-filled {
  background-color: #f8f9fa !important;
  border-color: #e2e6ea !important;
}

.info-message {
  background-color: #e8f4ff;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 13px;
  color: #0066cc;
}

.mb-3 {
  margin-bottom: 0.75rem;
}
</style>
